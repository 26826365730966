import React, { useEffect, useState } from "react";


import './App.css';
import { AdPage } from './pages/AdPage'


function App() {
    return (
        <div className="App" >
            <header className="App-header">
            </header>
            <AdPage />
        </div>
    );
}

export default App;
