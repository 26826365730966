import React, { useEffect, useState, useRef } from "react";

import search from '../../assets/images/search.png'

import icon from '../../assets/images/enhundring-ikon.png'

import "./AdStyles.css";

interface AdToolbarEnv {
    refreshCB?: () => void;
    searchCB?: (searchCriteria: string) => void;
    closeAllItemsCB?: () => void;
}

export function AdToolbar(props: AdToolbarEnv) {
    const searchFieldRef = useRef<null | HTMLInputElement>(null);

    const triggerRefresh = () => {
        props.refreshCB && props.refreshCB();

        scrollToTop();
    }

    const triggerSearch = (criteria: string) => {
        triggerCloseAllItems();

        props.searchCB && props.searchCB(criteria);

        scrollToTop();
    }

    const triggerCloseAllItems = () => {
        props.closeAllItemsCB && props.closeAllItemsCB();
    }

    const searchFieldKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode == 13) {
            const criteria = e.currentTarget.value;
            triggerSearch(criteria);
            e.currentTarget.blur();
        }
    }

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth"
        });
    }

    const iconClick = () => {
        if (searchFieldRef?.current) {
            searchFieldRef.current.value = "";
        }
        triggerSearch("");
        //triggerCloseAllItems();
        //triggerRefresh();
        //scrollToTop();
    }

    return (
        <div id="adToolbar" className="AdToolbar">
            {/*<span>Pris:</span><input type="text" /><br />*/}
            {/*<button className="btnRefresh" onClick={triggerRefresh}>Refresh</button>*/}
            {/*<h1>Ads</h1>*/}
            <img className="AppIcon" src={icon} onClick={iconClick} />
            <div className="SearchBox">
                <img src={search} className="SearchImage" /><input type="text" className="SearchField" ref={searchFieldRef} onKeyDown={searchFieldKeyPress} />
            </div>
        </div>
    );
}
