import React, { useState } from "react";

import { AdList } from '../features/ads/AdList'
import { AdToolbar } from "../features/ads/AdToolbar";

import logo from '../assets/images/enhundring-logo.png'

export function AdPage() {
    const [debugRefresh, setDebugRefresh] = useState(false)

    const [searchCriteria, setSearchCriteria] = useState("");

    const [refreshAt, setRefreshAt] = useState<Date>(new Date());

    const [requestCloseAllItemsAt, setRequestCloseAllItemsAt] = useState<Date>(new Date());

    const doReRender = () => {
        setDebugRefresh(!debugRefresh);
    }

    const callbackFromToolbar = (command: string) => {
        console.log("callback from toolbar: " + command);

        switch (command) {
            case "refresh":
                doReRender();
                break;
        }
    }

    const refreshCB = () => {
        setRefreshAt(new Date());
    }

    const searchCB = (criteria: string) => {
        setSearchCriteria(criteria);
    }

    const closeAllItemsCB = () => {
        setRequestCloseAllItemsAt(new Date());
    }

    const reloadPage = () => {
        window.location.reload();
    }

    return (
        <div className="AdViewer">
            <div style={{ textAlign: "center", padding: "0.5em" }} >
                <img src={logo} className="AppLogo" alt="En hundring" onClick={reloadPage} />
                <div className="AppSloganBox">
                    <span style={{ display: "inline-block", marginBottom: "1em", fontWeight: "bold" }} >V&auml;lkommen till enhundring.se</span><br />
                    <span style={{ fontStyle: "italic" }} >- samlingsplatsen f&ouml;r saker <br />som kostar en hundring</span>
                </div>
            </div>
            <AdToolbar refreshCB={refreshCB} searchCB={searchCB} closeAllItemsCB={closeAllItemsCB} />
            <AdList searchCriteria={searchCriteria} refreshAt={refreshAt} requestCloseAllItemsAt={requestCloseAllItemsAt} />
        </div>
    );
}
