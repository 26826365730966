import React, { useEffect, useState, useRef } from "react";

import { AdItemType } from "../../data/AdItemType"

interface AdItemProps {
    adData: AdItemType;
    expandedItem: string;
    closeAllItemsAt: Date;
    onExpanded?: (senderId: string) => void;
}


export function AdItem(props: AdItemProps) {
    const data: AdItemType = props.adData;

    const scrollRef = useRef<null | HTMLDivElement>(null);

    const [expanded, setExpanded] = useState(false);


    useEffect(() => {
        close();
        //if (props.expandedItem != data.id) {
        //    close();
        //}

    }, [props.closeAllItemsAt]);


    const scrollToMe = () => {
        if (scrollRef?.current) {
            let y = scrollRef?.current?.getBoundingClientRect()?.top + window.scrollY - 10;

            const toolbarHeight: number = document?.getElementById("adToolbar")?.getBoundingClientRect()?.height ?? 0;
            y -= toolbarHeight;

            try {
                const headerHeight: number = document?.getElementsByClassName("SectionHeaderInner")?.item(0)?.getBoundingClientRect()?.height ?? 0;
                /*console.log("header height: " + headerHeight);*/
                y -= (headerHeight / 2);
            } catch (ex) {

            }


            window.scroll({
                top: y,
                behavior: "smooth"
            });
        }
    }

    //const toggleExpanded = () => {
    //    if (expanded) {
    //        close();
    //    } else {
    //        showDetails();
    //    }
    //    setExpanded(!expanded);
    //}

    const showDetails = () => {
        //const heightBefore = scrollRef?.current?.getBoundingClientRect()?.height;

        props.onExpanded && props.onExpanded(data.adId);

        setExpanded(true);

        setTimeout(scrollToMe, 100);
    }

    const close = () => {
        setExpanded(false);
    }


    const getItemId = () => {
        return "item" + data.adId;
    }

    const getShortLocationString = (locationString: string) => {
        if (locationString) {
            const parts: string[] = locationString.split(',');
            if (parts.length >= 2) {
                const subParts: string[] = parts[0].split(' ');
                if (subParts.length >= 3) {
                    return subParts[2] + ", ";
                }
                else {
                    return parts[0] + ", ";
                }
            }
            else {
                return locationString + ", ";
            }
        }
        else {
            return "";
        }
    }

    const getFormattedBodyText = (rawText: string) => {
        const parts = (rawText ?? "").split('\n');

        const result = [];

        for (let p of parts) {
            result.push(p);
            result.push(<br />);
        }

        return result;
    }

    const getFormattedDateTime = (d: Date) => {
        const timeSince = new Date().getTime() - d.getTime();
        const hoursSince = timeSince / (1000 * 3600);
        const daysSince = hoursSince / 24;

        const hours = d.getHours();
        const minutes = d.getMinutes();
        const hhmm = pad2(hours) + ":" + pad2(minutes);

        if (d.getDate() === new Date().getDate()) {
            return "idag " + hhmm;
        } else if (daysSince < 7) {
            const options: Intl.DateTimeFormatOptions = { weekday: 'short' };

            return d.toLocaleDateString("sv-SE", options) + " " + hhmm;
        } else {
            const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };

            return d.toLocaleDateString("sv-SE", options);
        }
    }

    const pad2 = (v: number) => {
        let r: string = v.toString();
        while (r.length < 2) {
            r = "0" + r;
        }
        return r;
    }


    let innerItem = null;

    if (expanded) {
        innerItem = (
            <div className="AdItemExpanded" style={{ position: "relative" }} >{/*<a href={item.itemUrl} target="_blank">*/}
                <div className="AdItemPriceSmall" style={{ position: "absolute", left: "0", marginLeft: "0.3em", marginTop: "0.3em" }} >{data.priceValue} {data.priceCurrency}</div>
                {/*<div className="AdItemLocationSmall">{getShortLocationString(props.locationName)}</div>*/}
                <div className="AdItemCloseButton" onClick={close}>X</div>
                <img className="AdItemImageLarge" alt={data.title} src={data.galleryImageUrl + "?type=original"} />
                {/*<div className="AdItemPriceLarge" style={{ float: "right", right: "0" }} >{data.priceValue} {data.priceCurrency}</div>*/}

                <br />
                <h2 className="AdItemTitleLarge">{data.title}</h2>{/*</a>*/}
                <div className="AdItemLocationLarge">{data.locationName}</div>

                <div className="AdItemBodyText">{getFormattedBodyText(data.bodyText)}</div>
                <div><a href={data.itemUrl} target="_blank" rel="noreferrer"><div style={{ backgroundColor: "white", padding: "1em", display: "inline-block", borderRadius: "1em", marginTop: "1em" }} >Visa p&aring; {data.agencyName}</div></a></div>
            </div>
        )
    } else {
        let d = new Date(Date.parse(data.timeAdded));

        innerItem = (
            <div className="AdItem" onClick={showDetails}>
                <div className="AdItemPriceSmall" style={{ position: "absolute", left: "0", marginLeft: "0.3em" }} >{data.priceValue} {data.priceCurrency}</div>
                {/*<div className="AdItemLocationSmall">{getShortLocationString(props.locationName)}</div>*/}
                <img className="AdItemImageSmall" alt={data.title} src={data.galleryImageUrl} />
                <div className="AdLocationSmall">{getShortLocationString(data.locationName)}{getFormattedDateTime(d)}</div>
                <div className="AdItemText">{data.title}</div>
            </div>
        )
    }

    return (
        /*<a id={getItemId()} >*/
        <div key={data.adId} ref={scrollRef} id={getItemId()} style={{ display: "inline-block", verticalAlign: "top" }}>{innerItem}</div>
        /*</a>*/
    );

    //return (
    //    <div ref={scrollRef} id={getItemId()} className={expanded ? "AdItemExpanded" : "AdItem"} key={data.adId} onClick={toggleExpanded}>{/*<a href={item.itemUrl} target="_blank">*/}
    //        <img className="adItemImage" src={data.galleryImageUrl} />
    //        <br />
    //        <span className="adItemText">{data.title}</span>{/*</a>*/}
    //    </div>
    //);
}