
/**
 * Returns the complete url for the specified endpoint.
 * @param endpointName
 */
function getEndpointUrl(endpointName: string) {
         //return "https://localhost:32768" + endpointName;
    //return "https://localhost:44305" + endpointName;
    return "https://enhundring-api.azurewebsites.net" + endpointName;
}

export default getEndpointUrl;