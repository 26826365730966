import React, { useEffect, useState } from "react";

import getEndpointUrl from "../../common/apiUtils"
import { apiHandlerInstance } from "../../common/ApiHandler";

import { AdItemType } from "../../data/AdItemType"

import "./AdStyles.css";

import { AdItem } from "./AdItem"

interface AdListEnv {
    searchCriteria: string;
    refreshAt: Date;
    requestCloseAllItemsAt: Date;
}

export function AdList(props: AdListEnv) {
    const [adItems, setAdItems] = useState<AdItemType[]>([]);

    const [expandedItem, setExpandedItem] = useState<string>("");

    const [closeAllItemsAt, setCloseAllItemsAt] = useState<Date>(new Date());

    const [itemsLoadedLastTime, setItemsLoadedLastTime] = useState<number>(0);

    const [pageOffset, setPageOffset] = useState<number>(0);


    useEffect(() => {
        updateAdItems();

        if (props.requestCloseAllItemsAt > closeAllItemsAt) {
            collapseAllItems();
        }

    }, [props.searchCriteria, props.refreshAt, props.requestCloseAllItemsAt]);

    let lastScrollCallAt = new Date();

    window.onscroll = () => {
        if ((window.innerHeight * 2) + document.documentElement.scrollTop > document.documentElement.offsetHeight) {
            let timeSinceLast = new Date().getTime() - lastScrollCallAt.getTime();
            if (timeSinceLast > 1000) {
                updateAdItems(true);
                lastScrollCallAt = new Date();
            }
        }
    };

    const updateAdItems = (loadMore?: boolean) => {
        let maxCount = 40;

        let query = "?searchFor=" + props.searchCriteria + "&maxCount=" + maxCount;
        if (loadMore) {
            if (itemsLoadedLastTime == 0) {
                return;
            }

            let page = Math.floor(adItems.length / maxCount) + pageOffset;
            if (page > 0) {
                query += "&page=" + page;
            } else {
                return;
            }

            //if (adItems.length > 0) {
            //    let lastItem = adItems[adItems.length - 1];

            //    let d = new Date(Date.parse(lastItem.timeAdded));

            //    console.log(d);
            //}
        } else {
            setPageOffset(0);
        }

        fetch(getEndpointUrl("/items" + query))
            .then(response => {
                response.json().then(json => {
                    if (loadMore) {
                        let toAdd = new Array();
                        for (let item of json as AdItemType[]) {
                            if (!addedIds.has(item.adId)) {
                                toAdd.push(item);
                            }
                        }
                        setItemsLoadedLastTime(json.length);

                        if (toAdd.length == 0 && json.length == maxCount) {
                            setPageOffset(pageOffset + 1);
                        }

                        setAdItems([...adItems, ...toAdd]);
                    } else {

                        setItemsLoadedLastTime(json.length);

                        setAdItems(json);
                    }
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    const collapseAllItems = () => {
        setCloseAllItemsAt(new Date());
    }


    const onItemExpanded = (senderId: string) => {
        setExpandedItem(senderId);
    }

    const addedIds = new Set();

    let currentSection = "";

    return (
        <div className="AdList">
            {/*<div><button onClick={collapseAllItems} >Minimera</button></div>*/}

            {
                adItems.map(item => {
                    if (!addedIds.has(item.adId)) {
                        addedIds.add(item.adId);

                        if (item.sectionName != currentSection) {
                            currentSection = item.sectionName;
                            return ([
                                <div className="SectionHeader"><div className="SectionHeaderInner">{currentSection}</div></div>,
                                <AdItem key={item.adId} expandedItem={expandedItem} closeAllItemsAt={closeAllItemsAt} adData={item} onExpanded={onItemExpanded} />
                            ])
                        } else {
                            return (
                                <AdItem key={item.adId} expandedItem={expandedItem} closeAllItemsAt={closeAllItemsAt} adData={item} onExpanded={onItemExpanded} />
                            )
                        }

                        
                    }
                })
            }
        </div>
    );
}
